/**
 * A Vue mixin to map local component state to URL query.
 *
 * Uses vue-router's $route.query to get/set the query params.
 *
 * Useful e.g. to store and restore filters to/from the URL.
 *
 */
const {stringify} = JSON;
const debug = false;

// const debug = true;

/**
 * lifted from vue-router - don't think this is exported.  basically a url query string equal,
 * has behavior where 16 == "16" and so on.
 * */
function isObjectEqual(a = {}, b = {}) {
  // handle null value #1566
  if (!a || !b) return a === b
  const aKeys = Object.keys(a).sort()
  const bKeys = Object.keys(b).sort()
  if (aKeys.length !== bKeys.length) {
    return false
  }
  return aKeys.every((key, i) => {
    const aVal = a[key]
    const bKey = bKeys[i]
    if (bKey !== key) return false
    const bVal = b[key]
    // query values can be null and undefined
    if (aVal == null || bVal == null) return aVal === bVal
    // check nested equality
    if (typeof aVal === 'object' && typeof bVal === 'object') {
      return isObjectEqual(aVal, bVal)
    }
    return String(aVal) === String(bVal)
  })
}

const urlState = {
  data() {
    return {
      paramToUnwatcher: [],
      routeToUnwatcher: [],
      isUpdating: false,
    };
  },
  watch: {
    // Is extended/managed dynamically.
  },
  methods: {
    updateQuery(query) {
      if (isObjectEqual(this.$route.query, query)) {
        if (debug) console.log('query already set', {query});
        return;
      } else {
        if (debug) console.log('setting', stringify({query, currentQuery: this.$route.query}));
      }
      this.isUpdating = true;
      this.$router.push({'query': query})
        .finally(() => this.isUpdating = false);
    },
    syncToUrl({
                param,
                urlParam = null,
                transformCallback = null,
                parseCallback = null,
                initFromRoute = false,
                paramWatchOptions = {},
                routeWatchOptions = {}
              }) {
      if (urlParam === null) {
        urlParam = param;
      }
      if (debug) console.log('registering urlstate watch', {urlParam, param});

      const valueToRoute = value => {
        if (debug) console.log('urlstate param watch handled', {urlParam, param, value});
        if (transformCallback !== null) {
          value = transformCallback(value);
        }
        let query = Object.assign({}, this.$route.query);
        if (value === null || value === undefined || value === '') {
          delete query[urlParam];
        } else {
          query[urlParam] = value;
        }
        return this.updateQuery(query);
      };
      const routeToValue = value => {
        if (this.isUpdating) {
          if (debug) console.log('watch fired but updating, ignore', {urlParam, value});
          return;
        }
        if (debug) console.log('urlstate query watch handled', {urlParam, param, value});
        if (parseCallback !== null) {
          value = parseCallback(value);
        }
        if (JSON.stringify(this[param]) !== JSON.stringify(value)) {
          this[param] = value;
        } else {
          // delete query[urlParam];
        }
        // this.$router.push({'query': query});
      };

      this.paramToUnwatcher[param] = this.$watch(param, valueToRoute, paramWatchOptions);
      this.routeToUnwatcher[param] = this.$watch(`$route.query.${urlParam}`, routeToValue, routeWatchOptions);

      if (initFromRoute) {
        if (Object.keys(this.$route.query).includes(urlParam)) {
          routeToValue(this.$route.query[urlParam])
        }
      }
    },
    removeSyncToUrl(param) {
      if (this.paramToUnwatcher[param]) {
        this.paramToUnwatcher[param]();
        delete this.paramToUnwatcher[param];
      }
      if (this.routeToUnwatcher[param]) {
        this.routeToUnwatcher[param]();
        delete this.routeToUnwatcher[param];
      }
    },
  },
};

export default urlState;
